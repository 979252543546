<template>
	<div>
		<el-card>
      <!--查询-->
      <el-form ref="searchFormRef"
                :model="searchForm"
                label-width="100px">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="操作类型：" prop="handleType">
              <el-select v-model.number="searchForm.handleType"
                          placeholder="请选择"
                          @change="handlerTypeChange">
                <el-option v-for="item in handlerTypeList"
                          :key="item.code"
                          :label="item.desc"
                          :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="操作状态：" prop="handleStatus">
              <el-select v-model.number="searchForm.handleStatus" placeholder="请选择">
                <el-option
                        v-for="item in handlerStatusList"
                        :key="item.code"
                        :label="item.desc"
                        :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="票据包号：" prop="packageNo">
              <el-input v-model="searchForm.packageNo"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="操作编号：" prop="operNo">
              <el-input v-model="searchForm.operNo"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="出质人：" prop="debtor">
              <el-input v-model="searchForm.debtor"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="质权人：" prop="creditor">
              <el-input v-model="searchForm.creditor"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item class="btn-form-item">
            <el-button type="primary" @click="getTableList">查询</el-button>
            <el-button @click="handleReset">重置</el-button>
          </el-form-item>
        </el-row>
      </el-form>
      <!--列表-->
      <el-table :data="tableList" border>
        <el-table-column prop="handlerType" label="操作类型" min-width="110px"></el-table-column>
        <el-table-column prop="handlerStatus" label="操作状态" min-width="80px"></el-table-column>
        <el-table-column prop="packageNo" label="票据包号" min-width="150px"></el-table-column>
        <el-table-column prop="ticketRange" label="子票区间" min-width="130px"></el-table-column>
        <el-table-column prop="operResult" label="操作结果" min-width="130px"></el-table-column>
        <el-table-column prop="packageSum" label="票据包金额（元）" min-width="140px"></el-table-column>
        <el-table-column prop="expireDate" label="票据到期日" min-width="100px"></el-table-column>
        <el-table-column prop="acceptor" label="承兑人" min-width="200px"></el-table-column>
        <el-table-column prop="debtor" label="出质人" min-width="200px"></el-table-column>
        <el-table-column prop="creditor" label="质权人" min-width="200px"></el-table-column>
        <el-table-column prop="ticketType" label="票据种类" min-width="90px"></el-table-column>
        <el-table-column prop="operNo" label="操作编号" min-width="170px"></el-table-column>
      </el-table>
      <!--分页区域-->
      <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="searchForm.pageNum"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="searchForm.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              class="mt20">
      </el-pagination>
    </el-card>
	</div>
</template>

<script>
  export default {
    name: "PledgeRecall",
    props: ['handlerTypeList'],
	  mounted(){
      // 获取列表数据
		  this.getTableList()
	  },
	  data(){
      return {
        // 搜索表单数据
        searchForm: {
          handleType: '', // 操作类型
          handleStatus: '', // 操作状态
          packageNo: '', //票据包号
          operNo: '', // 操作编号
          debtor: '', // 出质人
          creditor:'', // 质权人
          pageSize: 10,  //当前页面数据量
          pageNum: 1,    //当前页码
        },
        
        handlerStatusList: [], // 操作状态
	      tableList: [], // 列表数据
	      total:0, //总条数
        faceUrl: '', // 票面链接
      }
	  },
	  methods: {
		  // 监听操作类型值发生变化
      async handlerTypeChange(value){
		    const {data: res} = await this.$auth.get(`/cpiaoju-ticket/operate/query/handlerStatuses/${value}`);
	      if(res.code !== 200) return this.$message.error(res.msg)
        this.handlerStatusList = res.data
      },
		  // 获取列表数据
		  async getTableList(){
		    const {data: res} = await this.$auth.get("/cpiaoju-ticket/operate/query/dePledge/page",{params:this.searchForm})
			  if(res.code !== 200) return this.$message.error(res.msg)
			  this.tableList = res.data.records
			  this.total = res.data.total
		  },
      handleReset(){
		    this.$refs.searchFormRef.resetFields()
	      this.getTableList()
      },
      // 监听pagesize改变
      handleSizeChange(newSize){
        this.searchForm.pageSize = newSize;
        this.getTableList()
      },
      // 监听pagenum改变
      handleCurrentChange(newPage){
        this.searchForm.pageNum = newPage;
        this.getTableList()
      },
	  }
  }
</script>

<style lang="less" scoped>
	.el-select{
		width: 100%;
	}
	.btn-form-item{
		text-align: center;
		.el-button{
			width: 160px;
		}
	}
	/*票面*/
	#printTest{
		width: 800px;
		height: 500px;
		.face-url{
			width: 100%;
			height: 100%;
		}
	}
</style>