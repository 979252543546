<template>
	<div>
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item>票据查询 </el-breadcrumb-item>
			<el-breadcrumb-item>操作查询</el-breadcrumb-item>
		</el-breadcrumb>
		<!-- tab -->
		<el-tabs v-model="activeName" type="card" @tab-click="handleTabClick">
			<el-tab-pane label="出票操作" name="10000000">
				<draw-bill :handlerTypeList="handlerTypeList"></draw-bill>
			</el-tab-pane>
			<el-tab-pane label="背书操作" name="20000000">
				<endorse :handlerTypeList="handlerTypeList"></endorse>
			</el-tab-pane>
			<el-tab-pane label="贴现操作" name="30000000">
				<discount :handlerTypeList="handlerTypeList"></discount>
			</el-tab-pane>
			<el-tab-pane label="质押操作" name="70000000">
				<pledge-operation :handlerTypeList="handlerTypeList"></pledge-operation>
			</el-tab-pane>
			<el-tab-pane label="质押解除操作" name="80000000">
				<pledge-recall :handlerTypeList="handlerTypeList"></pledge-recall>
			</el-tab-pane>
			<el-tab-pane label="保证操作" name="60000000">
				<guarantee :handlerTypeList="handlerTypeList"></guarantee>
			</el-tab-pane>
			<el-tab-pane label="提示付款操作" name="40000000">
				<payment :handlerTypeList="handlerTypeList"></payment>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
	import DrawBill from './children/DrawBill.vue' // 出票操作
	import Endorse from './children/Endorse.vue'	// 背书操作	
	import Discount from './children/Discount.vue' // 贴现操作
	import PledgeOperation from './children/PledgeOperation.vue' // 质押操作
	import PledgeRecall from './children/PledgeRecall.vue' // 质押解除操作
	import Guarantee from './children/Guarantee.vue' // 保证操作
	import Payment from './children/Payment.vue' // 提示付款操作
  export default {
		name: 'queryBill',
  	components: {
			DrawBill,
			Endorse,
			Discount,
			PledgeOperation,
			PledgeRecall,
			Guarantee,
			Payment
		},
		mounted(){
			// 获取操作类型
		  this.getHandlerType(this.activeName)
		},
		data(){
			return {
				activeName: '10000000', // 
				handlerTypeList: [], //操作类型
			}
		},
		methods: {
			// 获取操作类型
		  async getHandlerType(handleClass){
		    const {data: res} = await this.$auth.get(`/cpiaoju-ticket/operate/query/handleTypes/${handleClass}`);
		    if (res.code !== 200) return this.$message.error(res.msg)
			  this.handlerTypeList = res.data
		  },
			// 监听tab点击
			handleTabClick(tab, event) {
        const handleClass = tab._props.name
        this.getHandlerType(handleClass)
      }
		}
  }
</script>
<style lang="less" scoped>
	.el-tabs{
		margin-top: 30px;
	}
</style>
